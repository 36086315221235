import { selectorAuthWebState } from '../../../app-view/auth-web';
import { useDispatch, useSelector } from 'react-redux';
import UserSelection from './UserSelection';
import LoginWeb from './LoginWeb';
import voicemeLoading from '../../../assets/images/voiceme-loading.svg'
import Loader from '../../organism/Loader';
import Message from '../../organism/Message';
import { Navigate, useNavigate } from 'react-router';
import { authWebStartup } from '../../../app/auth-web/actions';

interface Prop {
    okComplete: () => void
    okOnError: () => void
}

function AuthWeb({okComplete, okOnError }: Prop) {
    const flow = useSelector(selectorAuthWebState)
    switch(flow.status) {
        case 'request-user':
            return (<UserSelection></UserSelection>);
        case 'show-video':
            return (<LoginWeb></LoginWeb>);
        case 'loading-phrase':
            return (<Loader/>);
        case 'complete':
            return (<Message severity='success' title='Login completed' onClick={okComplete}/>);
        case 'auth-error-sentence':
            return (<Message title={`Error during connection with the server: ${flow.error ?? 'timeout'}`} onClick={okOnError}/>);
        case 'auth-error-auth':
            return (<Message title={`Authorization failed: ${flow.error ?? 'timeout'}`} onClick={okOnError}/>);
    }
}

export default AuthWeb;
