import { ReactNode, useState } from 'react';
import { Form } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { AuthGetRequest } from '../../app/api/types';

interface Prop {
    onClick?: ({ email, submitButton }: {email:string, submitButton: string}) => void
    children?: ReactNode,
    hideSubmit?: boolean,
}

 

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
  }));

function FormEmail({ onClick, children, hideSubmit }: Prop) {
    const [validatedEmail, setValidatedEmail] = useState<string|undefined>(undefined);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        const emailField = form.elements.email;
        const submitButton = event.nativeEvent.submitter; 
        onClick?.({ email: emailField.value, submitButton: submitButton.innerText })
    }

    const handleValidation = (event: any) => {
        const emailField = event.currentTarget;
        // Email validation
        const emailRegex = /^\S+@\S+\.\S+$/;
        if (emailRegex.test(emailField.value)) {
            setValidatedEmail(undefined);
        } else {
            setValidatedEmail("Please enter a valid email address.");
        }
    };
    const style = useStyles()
    return (
        <div className={style.page}>
            <Form className={style.form} onSubmit={handleSubmit}>
                <TextField  className={style.item} id="email" error={validatedEmail !== undefined} onChange={handleValidation} helperText={validatedEmail} label="Please enter your Email" variant="outlined" type="email" color='primary' defaultValue="" />
                <div className={style.item} ></div>
                {!hideSubmit && <Button className={style.itemHalf} variant='outlined' type="submit">Submit</Button>}
                {children}
            </Form>
        </div>
    );
}

export default FormEmail;
