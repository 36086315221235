import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../organism/Loader';
import { useEffect } from 'react';
import { OIDCPayload } from '../../../app/api/types';
import { oidcVoiceOtpNotify, oidcVoiceOtpStart } from '../../../app/oidc-otp/actions';
import FormEmail from '../../organism/FormEmail';
import { selectorOidcOtpState } from '../../../app/oidc-otp/selector';

interface Prop {
}

function OidcOtp({ }: Prop) {
    const dispatch = useDispatch()
    const flow = useSelector(selectorOidcOtpState)
    const handleVoiceOTP = (request: {email:string}) => {
        dispatch(oidcVoiceOtpNotify({service_id: "VOICEME", ...request}))
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const oidcPayload: OIDCPayload = {
            email: urlParams.get('email') || '',
            client_id: urlParams.get('client_id') || '',
            redirect_uri: urlParams.get('redirect_uri') || '',
            scope: urlParams.get('scope') || '',
            response_type: urlParams.get('response_type') as OIDCPayload['response_type'] || 'token',
            response_mode: urlParams.get('response_mode') as OIDCPayload['response_mode'] || 'query',
            state: urlParams.get('state') || '',
            nonce: urlParams.get('nonce') || '',
            code_challenge_method: urlParams.get('code_challenge_method') as OIDCPayload['code_challenge_method'] || undefined,
            code_challenge: urlParams.get('code_challenge') || undefined,
        };
        dispatch(oidcVoiceOtpStart(oidcPayload))
        if(!!oidcPayload.email) setTimeout(()=>handleVoiceOTP({email: oidcPayload.email!}), 300)
    }, [dispatch])
    switch(flow.status) {
        case 'request-otp-notify':
            return (<Loader/>);
        case 'request-otp-auth':
        default:
            return (<FormEmail onClick={handleVoiceOTP}></FormEmail>);
    }
}

export default OidcOtp;
