import { createSlice } from '@reduxjs/toolkit';
import { apiToken } from '../api/actions';
import { OidcVoiceOtp } from './types';
import { oidcVoiceOtpNotify, oidcVoiceOtpStart } from './actions';

const initialState: OidcVoiceOtp = {
    status: "request-otp-auth"
};

export const oidcOtp = createSlice({
  name: 'oidc-otp',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(oidcVoiceOtpStart, (state, action) => {
      state.status = "request-otp-auth"
      state.oidcParms = action.payload
    })
    .addCase(oidcVoiceOtpNotify, (state, action) => {
      state.status = "request-otp-notify"
    })
  },
});

export default oidcOtp.reducer;
