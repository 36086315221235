

import { Action } from "redux";
import { Observable, of } from "rxjs";
import { delay, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { apiToken, callApiStart } from "../api/actions";
import { RootState } from "../store";
import { confirmFailed, confirmProcessed, confirmTrigger, enrollFailed, enrollGetFailed, enrollGetProcessed, enrollGetTrigger, enrollProcessed, enrollTrigger, signupFailed, signupProcessed, signupTrigger } from "./actions";
import { routerTo } from "../router/actions";

export const epicSignupTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(signupTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.enroll.status === 'loading' && !!state.api.api),
        map(([action, state]) => {
            return callApiStart({
                api: `${state.api.api!.endpoint}`,
                path: `${state.api.api!.signup}`,
                method: "POST",
                body: JSON.stringify({ ...action.payload }),
                success: signupProcessed,
                reject: signupFailed,
                auth: false,
            })
        })
    );

export const epicConfirmTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(confirmTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.enroll.status === 'loading' && !!state.api.api),
        map(([action, state]) => {
            return callApiStart({
                api: `${state.api.api!.endpoint}`,
                path: `${state.api.api!.confirm}`,
                method: "POST",
                body: JSON.stringify({ ...action.payload }),
                success: confirmProcessed,
                reject: confirmFailed,
                auth: false,
            })
        })
    );

export const epicConfirmProcessed = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(confirmProcessed.match),
        mergeMap(action =>
            of(
                apiToken(action.payload),
                enrollGetTrigger({}),
            ))
    );


export const epicEnrollTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(enrollTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.enroll.status === 'loading' && !!state.api.api),
        map(([action, state]) => {
            return callApiStart({
                api: `${state.api.api!.endpoint}`,
                path: `${state.api.api!.enroll}`,
                method: "POST",
                body: JSON.stringify({ ...action.payload }),
                success: enrollProcessed,
                reject: enrollFailed,
                auth: true,
            })
        })
    );

export const epicEnrollGetTrigger = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(enrollGetTrigger.match),
        withLatestFrom(state$),
        filter(([, state]) => state.enroll.status === 'loading' && !!state.api.api),
        map(([action, state]) => {
            return callApiStart({
                api: `${state.api.api!.endpoint}`,
                path: `${state.api.api!.enroll}`,
                method: "GET",
                success: enrollGetProcessed,
                reject: enrollGetFailed,
                auth: true,
            })
        })
    );