import { useAppDispatch } from '../../app/hooks';
import { ReactNode } from 'react';
import { m2mAuthorizeClear, m2mAuthorizeTrigger, notificationPostTrigger } from '../../app/api/actions';
import { useSelector } from 'react-redux';
import { selectNotificationPost } from '../../app-view/api-view';
import Message from '../organism/Message';
import FormEmail from '../organism/FormEmail';
import { NotificationResponse } from '../../app/api/types';


interface Prop {
    children?: ReactNode,
}
function VoiceOTPLoginFlow({children} : Prop) {
    const dispatch = useAppDispatch()
    const notificationPost = useSelector(selectNotificationPost)
    const handleSubmit = (request: {key :string, secret: string }) => {
        dispatch(m2mAuthorizeTrigger(request))
    }
    const handleClear = () => {
        dispatch(m2mAuthorizeClear())
    }
    const handleVoiceOTP = (request: {email:string}) => {
        dispatch(notificationPostTrigger({service_id: "VOICEME", ...request}))
    }
    if(notificationPost?.response?.status === 'ok') {
        const notificationPostOk = notificationPost as unknown as NotificationResponse
        if(notificationPostOk.status === 'NOTIFIED') {
            return <></>
        } else {
            return <Message  title='Congratulation' onClick={handleClear} >Login VOICEOTP success.</Message>
        }
    }
    return <FormEmail onClick={handleVoiceOTP}>{children}</FormEmail>
}
export default VoiceOTPLoginFlow;
