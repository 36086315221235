import { useAppDispatch } from '../../../app/hooks';
import { authWebSetup } from '../../../app/auth-web/actions';
import { AuthGetRequest } from '../../../app/api/types';
import FormEmail from '../../organism/FormEmail';

interface Prop {
}

function UserSelection({ }: Prop) {
    const dispatch = useAppDispatch()
    const handleUserSelection = (setup: AuthGetRequest) => {
        dispatch(authWebSetup(setup))
    }
    return (
        <>
            <FormEmail onClick={handleUserSelection}/>
        </>
    );
}

export default UserSelection;
