import { useAppDispatch } from '../../app/hooks';
import { ReactNode, useEffect } from 'react';
import { documentGetTrigger, m2mAuthorizeClear, m2mAuthorizeTrigger, notificationPostTrigger } from '../../app/api/actions';
import { useSelector } from 'react-redux';
import { selectNotificationPost } from '../../app-view/api-view';
import Message from '../organism/Message';
import FormEmail from '../organism/FormEmail';
import { NotificationResponse, OIDCPayload } from '../../app/api/types';
import { selectDocument, selectDocuments } from '../../app-view/document-view';
import DocumentSign from '../organism/DocumentSign';
import VoicemeButton from '../atoms/VoicemeButton';
import queryString from 'query-string';


interface Prop {
    children?: ReactNode,
}
function DemoOIDC({children} : Prop) {
    const dispatch = useAppDispatch()
    const items = useSelector(selectDocuments)
    const params = {
        "dev": {
            client_id: "688872",
            redirect_uri: "https://oidcdebugger.com/debug",
            scope: "openid",
            response_type: "code token",
            response_mode: "query",
            state: "w9dlpknsrqn",
            nonce: "4uvw07bx6th",
        } as OIDCPayload,
        "staging": {
            client_id: "912165",
            redirect_uri: "https://oidcdebugger.com/debug",
            scope: "openid",
            response_type: "code token",
            response_mode: "query",
            state: "w9dlpknsrqn",
            nonce: "4uvw07bx6th",
        } as OIDCPayload,
        "production": {
            client_id: "912165",
            redirect_uri: "https://oidcdebugger.com/debug",
            scope: "openid",
            response_type: "code token",
            response_mode: "query",
            state: "w9dlpknsrqn",
            nonce: "4uvw07bx6th",
        } as OIDCPayload,
    } as {[key: string]: OIDCPayload}
    const env = window.location.hostname.replace(/ui-saas\.voiceme-(dev|staging|production)\.demo\.infra\.voiceme\.id/,"$1") as string
    const paramsEnv = params[env] as OIDCPayload ?? params["dev"]
    const handlerWeb = () => {
        window.location.replace(`/oidc/voiceweb?${queryString.stringify(paramsEnv)}`)
    }
    const handlerOtp = () => {
        window.location.replace(`/oidc/voiceotp?${queryString.stringify(paramsEnv)}`)
    }
    return (
        <>
            <VoicemeButton onClick={handlerWeb}>Trigger demo OIDC Web</VoicemeButton>
            <br></br>
            <VoicemeButton onClick={handlerOtp}>Trigger demo OIDC Otp</VoicemeButton>
        </>
    )
}
export default DemoOIDC;
