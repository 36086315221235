import { createSlice } from '@reduxjs/toolkit';
import { confirmFailed, confirmProcessed, confirmTrigger, enrollFailed, enrollGetFailed, enrollGetProcessed, enrollGetTrigger, enrollProcessed, enrollTrigger, signupFailed, signupProcessed, signupTrigger } from './actions';
import { EnrollStore } from './store';

const initialState: EnrollStore = {
  status: 'idle',
};

export const signupStore = createSlice({
  name: 'enroll',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(signupTrigger, (state, action) => {
      state.status = 'loading'
      state.request = {
        payload: action.payload
      }
      state.error = undefined
    })
    .addCase(signupProcessed, (state) => {
      state.status = 'idle'
      state.error = undefined
    })
    .addCase(signupFailed, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    .addCase(confirmTrigger, (state, action) => {
      state.status = 'loading'
      state.request = {
        payload: action.payload
      }
      state.error = undefined
    })
    .addCase(confirmProcessed, (state) => {
      state.status = 'idle'
      state.error = undefined
    })
    .addCase(confirmFailed, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    .addCase(enrollTrigger, (state, action) => {
      state.status = 'loading'
      state.request = {
        payload: action.payload
      }
      state.error = undefined
    })
    .addCase(enrollProcessed, (state) => {
      state.status = 'idle'
      state.error = undefined
    })
    .addCase(enrollFailed, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    .addCase(enrollGetTrigger, (state, action) => {
      state.status = 'loading'
      state.request = {
        payload: action.payload
      }
      state.enroll = undefined
      state.error = undefined
    })
    .addCase(enrollGetProcessed, (state, action) => {
      state.status = 'idle'
      state.enroll = action.payload
      state.error = undefined
    })
    .addCase(enrollGetFailed, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  },
});

export default signupStore.reducer;
