

import { Action } from "redux";
import { Observable, merge, of } from "rxjs";
import { filter,  ignoreElements,  map,  switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "../store";
import { notificationGetProcessed, notificationPostProcessed, notificationPostTrigger, oidcRedirectProcessed, oidcRedirectTrigger } from "../api/actions";
import { oidcSuccess, oidcVoiceOtpNotify  } from "./actions";

export const epicOidcVoiceOtp = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(oidcVoiceOtpNotify.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        switchMap(([action, state]) =>
            merge(
                of(notificationPostTrigger(action.payload)),
                action$.pipe(
                    filter(notificationPostProcessed.match),
                    take(1),
                    switchMap((action2 => {
                        return merge(
                            action$.pipe(
                                filter(notificationGetProcessed.match),
                                filter(action => action2.payload.session_id == action.payload.session_id),
                                filter(action => action.payload.token != undefined),
                                take(1),
                                map(() => oidcSuccess(state.oidcOtp.oidcParms!))
                            ),
                        )
                    }))
                )
            )
        ));

export const epicOidcSuccess = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(oidcSuccess.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        switchMap(([action, ]) => {
            return merge(
                of(oidcRedirectTrigger(action.payload)),
                action$.pipe(
                    filter(oidcRedirectProcessed.match),
                    take(1),
                    tap((action) => window.location.replace(action.payload.location)),
                    ignoreElements(),
                )
            )
        })
    );
    