

import { Action } from "redux";
import { Observable, merge, of } from "rxjs";
import { filter,  ignoreElements,  map,  switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "../store";
import { notificationGetProcessed, notificationPostProcessed, notificationPostTrigger, oidcRedirectProcessed, oidcRedirectTrigger } from "../api/actions";
import { authWebComplete } from "../auth-web/actions";
import { oidcWebReset } from "./actions";
 
export const epicOidcWebSuccess = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(authWebComplete.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        filter(([, state]) => !!state.oidcWeb.redirect),
        switchMap(([action, state]) => {
            return merge(
                of(oidcRedirectTrigger(state.oidcWeb.redirect!)),
                action$.pipe(
                    filter(oidcRedirectProcessed.match),
                    take(1),
                    tap((action) => window.location.replace(action.payload.location)),
                    map(a => oidcWebReset()),
                )
            )
        })
    );
    