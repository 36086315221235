import { selectorAuthWebState } from '../../../app-view/auth-web';
import { useDispatch, useSelector } from 'react-redux';
import voicemeLoading from '../../../assets/images/voiceme-loading.svg'
import Loader from '../../organism/Loader';
import Message from '../../organism/Message';
import { Navigate, useNavigate } from 'react-router';
import { authWebSetup, authWebStartup } from '../../../app/auth-web/actions';
import AuthWeb from '../AuthWeb';
import { selectorOidcWebState } from '../../../app/oidc-web/selector';
import { useEffect } from 'react';
import { oidcWebStart } from '../../../app/oidc-web/actions';
import { OIDCPayload } from '../../../app/api/types';
import { concat, of } from 'rxjs';

interface Prop {
}

function OidcWeb({ }: Prop) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const flow = useSelector(selectorOidcWebState)
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const oidcPayload: OIDCPayload = {
            email: urlParams.get('email') || '',
            client_id: urlParams.get('client_id') || '',
            redirect_uri: urlParams.get('redirect_uri') || '',
            scope: urlParams.get('scope') || '',
            response_type: urlParams.get('response_type') as OIDCPayload['response_type'] || 'token',
            response_mode: urlParams.get('response_mode') as OIDCPayload['response_mode'] || 'query',
            state: urlParams.get('state') || '',
            nonce: urlParams.get('nonce') || '',
            code_challenge_method: urlParams.get('code_challenge_method') as OIDCPayload['code_challenge_method'] || undefined,
            code_challenge: urlParams.get('code_challenge') || undefined,
        };
        if(!!oidcPayload.email) {
            dispatch(authWebSetup({email: oidcPayload.email}))
            dispatch(oidcWebStart(oidcPayload))
        } else {
            dispatch(authWebStartup())
            dispatch(oidcWebStart(oidcPayload))
        }
    }, [dispatch])
    const okComplete = () => {
        dispatch(authWebStartup())
        navigate('/oidc/voiceweb')
    }
    const okOnError = () => {
        dispatch(authWebStartup())
        navigate('/oidc/voiceweb')
    }
    switch(flow.status) {
        case 'request-web-auth':
        default:
            return (<AuthWeb okComplete={okComplete} okOnError={okOnError}/>);
    }
}

export default OidcWeb;
